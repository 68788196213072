import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $messages = document.querySelector('#messages');
    $messages.scrollTop = $messages.scrollHeight;
  }

  reset_form() {
    this.element.reset();
  }

  validate_message(e) {
    const $messageBody = document.querySelector('#chat_message_body').value;

    if (!$messageBody) {
      e.detail.formSubmission.stop();
    }
  }

  set_chatname() {
    document.querySelector('.chatname-overlay').classList.toggle("d-none");
  }

  popout(e) {
    e.preventDefault();
    const windowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=600,height=800";
    window.open(e.target.href, "_chat_popout", windowFeatures);
  }
}
