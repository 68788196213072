import { Controller } from "stimulus"
import 'tom-select'
import TomSelect from "tom-select"

// Connects to data-controller="dynamic-instructor"
export default class extends Controller {
  connect() {
    var settings = {
      valueField: "id",
      searchField: ['displayString'],
      labelField: 'displayString',
      maxOptions: 50,
      maxItems: 1,
      load: function(query, callback) {
        fetch(`/admin/instructors?q[full_name_or_email_cont]=${query}`, { method: 'get' })
        .then((response) => response.json())
        .then((items) => callback(items))
      }
    }
    new TomSelect(this.element, settings);
  }
}
