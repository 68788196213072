import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["studios", "branch"]

  connect() {
    this.changed()
  }

  changed() {
    if (this.branchTarget.checked) {
      this.studiosTarget.classList.remove("d-none")
    } else {
      this.studiosTarget.classList.add("d-none")
      this.studiosTarget.querySelectorAll("[type='checkbox']").forEach(checkbox => {
        checkbox.checked = false
      });
    }
  }
}
