import Flickity from "flickity";
require('flickity-imagesloaded');

$(window).on('turbo:load resize', function() {
  var body_width = $(window).width()
  var carousel_wrapper_width = $('.js_carousel').parent().width()

  // Set breakpoints
  var xlarge_screen = (body_width >= 1440)
  var large_screen = (body_width >= 992 && body_width < 1440)
  var medium_screen = (body_width >= 768 && body_width < 992)
  var small_screen = (body_width < 768)

  // Set images width and carousel alignment
  $.fn.carousel_slides = function() {
    var js_carousel = $(this).find('.js_carousel')
    var videos_count = js_carousel.data('count')
    var id = "#" + $(this).attr("id")

    // SET NUMBER OF SLIDES TO BE SHOWN
    //  Default values
    var slides_xl = 5
    var slides_lg = 4
    var slides_md = 3
    var slides_sm = 2

    //  Custom values
    if ( id == "#trainers" ) {
      var slides_xl = 8
      var slides_lg = 6
      var slides_md = 4
      var slides_sm = 3
    }

    // Calculate images container width
    // Leave room on left and right for prev & next buttons respectively

    if (small_screen) {
      var prev_next_buttons_width = 0
      var image_wrapper_width = (carousel_wrapper_width - 2 * prev_next_buttons_width ) / slides_sm
    }
    if (medium_screen) {
      var prev_next_buttons_width = 48
      var image_wrapper_width = (carousel_wrapper_width - 2 * prev_next_buttons_width) / slides_md
    }
    if (large_screen) {
      var prev_next_buttons_width = 64
      var image_wrapper_width = (carousel_wrapper_width - 2 * prev_next_buttons_width) / slides_lg
    }
    if (xlarge_screen) {
      var prev_next_buttons_width = 64
      var image_wrapper_width = (carousel_wrapper_width - 2 * prev_next_buttons_width ) / slides_xl
    }


    // images width (equal to its container)
    $(id + ' .carousel-child').css("width", image_wrapper_width)

    // carousel alignment and ( left padding when not centered )
    var alignment = 'center'
    if ( id == "#trainers" ) {
      var alignment = 'left'
    }
    if ( videos_count < slides_sm ) {
      var alignment = 'left'
      js_carousel.css("padding-left", prev_next_buttons_width)
    }
    if (medium_screen && videos_count < slides_md) {
      var alignment = 'left'
      js_carousel.css("padding-left", prev_next_buttons_width)
    }
    if (large_screen && videos_count < slides_lg) {
      var alignment = 'left'
      js_carousel.css("padding-left", prev_next_buttons_width)
    }
    if (xlarge_screen && videos_count < slides_xl) {
      var alignment = 'left'
      js_carousel.css("padding-left", prev_next_buttons_width)
    }

  // Flickity settings
    if(js_carousel) {
      new Flickity(js_carousel[0],{
        imagesLoaded: true,
        groupCells: true,
        pageDots: false,
        cellAlign: alignment
      });
    }
  };

  // categories/show.html.erb
  $('.category_sliders').each(function(i, obj) {
    $(this).carousel_slides();
  });
  // assoc/show.html.erb
  $('.video-row').each(function(i, obj) {
    $(this).carousel_slides();
  });
  // worlds/show.html.erb
  $('.world-video-row').each(function(i, obj) {
    $(this).carousel_slides();
  });
  // worlds/show.html.erb trainers section
  $('.world-trainer-row').each(function(i, obj) {
    $(this).carousel_slides();
  });
});
