import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "labels", "links", "template"]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    console.log(this.wrapperClass)
    //this.lablelClass = document.getElementsByClassName("options-row-0")
  //  console.log(this.labelClass)
  }

  add_hours(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_hours(event, index) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)
    console.log(wrapper.dataset.newRecord)
    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  toggle_options(event) {
    event.preventDefault()

    console.log(event.params)
    let label_row = document.getElementById("options-row-" + event.params.id)
    let show_label_link = document.getElementById("show-options-" + event.params.id)
    console.log(label_row)
    console.log(label_row.classList)


    if (label_row.classList.contains("d-none")) {
      label_row.classList.remove("d-none")
      show_label_link.innerHTML = "Hide Options"
    }
    else {
      label_row.classList.add("d-none")
      show_label_link.innerHTML = "Show Options"
    }
  }

  toggle_readonly(event) {
    console.log("setting field state")
    console.log(event.params)
    let day_closed = document.getElementById("hours_of_operation_open_days_attributes_" + event.params.id + "_closed").checked
    console.log(day_closed)
    let hours_inputs = document.querySelectorAll("input[name^='hours_of_operation[open_days_attributes][" + event.params.id + "][open_hours_attributes]']")
    console.log(hours_inputs)
    let hours_button = document.getElementById("hours-" + event.params.id)

    if (day_closed == true) {
      hours_inputs.forEach(e=>{
        console.log("day_closed false. setting disabled to true")
        e.disabled = true

        e.val = ""
      })
      hours_button.disabled = true
    } else {
      hours_inputs.forEach(e=>{
        console.log("day_closed true. setting disabled to false")
        e.removeAttribute("disabled")
      })
      hours_button.removeAttribute("disabled")
    }
  }
}
