import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["element", "select"]

  connect() {
    this.changed();
  }

  changed() {
    let target_value = this.selectTarget.value == "InStudio (YMCA360 Virtual Instructor)" ? "instructor_name" : "instructor_id";
    for (let element of this.elementTargets) {
      this.toggle(element, element.dataset.values, target_value);
    }
  }

  toggle(element, values, value) {
    if (element && values) {
      let hidden = true;
      for (let _value of values.split(",")) {
        if (_value === value) {
          hidden = false;
        }
      }
      element.hidden = hidden;
    }
  }

}
