$(document).on('turbo:load', function() {
  if ($(window).height() < $('.banner').outerHeight()) {
    $('.arrow_down_js').addClass("fix_arrow_bottom")
  }
});

$(window).on('scroll', function() {
  if ($(this).scrollTop() > 35) {
    $('.arrow_down_js').fadeOut();
  }
});
