import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "image" ]

  update() {
    const [file] = event.target.files
    if (file) {
      this.imageTarget.src = URL.createObjectURL(file)
    }
  }
}
