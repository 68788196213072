import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "field", "output" ]

  connect() {
    this.change()
  }

  change() {
    if (this.fieldTarget.checked) {
      this.outputTarget.classList.remove("d-none")
    } else {
      this.outputTarget.classList.add("d-none")
    }
  }
}
