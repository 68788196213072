import ahoy from "ahoy.js";
import videojs from 'video.js';
import { registerIVSTech } from 'amazon-ivs-player';
import { registerIVSQualityPlugin } from 'amazon-ivs-player';
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

require('!style-loader!css-loader!video.js/dist/video-js.css')

window.liveVideoInit = function () {
  registerIVSTech(videojs, {
    wasmWorker: new URL(wasmWorkerPath, document.URL).toString(),
    wasmBinary: new URL(wasmBinaryPath, document.URL).toString(),
  });
  registerIVSQualityPlugin(videojs);

  const videoJSPlayer = videojs("live-stream-videojs", {
    techOrder: ["AmazonIVS"],
    controlBar: {
      playToggle: {
        replay: false
      },
      pictureInPictureToggle: false
    }
  });

  var last_position = 0;

  const playhead_tracking = function () {
    const videoContainerEl = document.querySelector("#live-stream-videojs");
    var position = Math.floor(videoJSPlayer.currentTime());
    var startTime = videoContainerEl.dataset.startTime;
    var currentTime = Math.floor(Date.now() / 1000);
    if (position >= last_position + 15) {
      ahoy.track(
        "Playhead Position",
        {
          "stream_id": videoContainerEl.dataset.id,
          "position": currentTime - startTime
        }
      )
    }
    last_position = position;
    setTimeout(playhead_tracking, 15500);
  }

  const readyCallback = function () {
    window.videoJSPlayer = videoJSPlayer;
    const ivsPlayer = videoJSPlayer.getIVSPlayer();

    // Show the "big play" button when the stream is paused
    const videoContainerEl = document.querySelector("#live-stream-videojs");
    videoContainerEl.addEventListener("click", () => {
      if (videoJSPlayer.paused()) {
        videoContainerEl.classList.remove("vjs-has-started");
      } else {
        videoContainerEl.classList.add("vjs-has-started");
      }
    });

    // Handle the end of the stream
    const PlayerState = videoJSPlayer.getIVSEvents().PlayerState;
    ivsPlayer.addEventListener(PlayerState.PLAYING, () => {
      // console.log("Player State - Playing");
      ahoy.track("Stream Play", { "stream_id": videoContainerEl.dataset.id });
    });

    ivsPlayer.addEventListener(PlayerState.READY, () => {
      // console.log("Player State - Ready");
      $(".vjs-waiting.starting").addClass("vjs-hidden");
    });

    ivsPlayer.addEventListener(PlayerState.ENDED, () => {
      // console.log("Player State - Ended");
      $(".vjs-waiting.ended").removeClass("vjs-hidden");
      videoJSPlayer.exitFullscreen();
      videoJSPlayer.hasStarted(false);
      videoJSPlayer.controls(false);
      videoJSPlayer.bigPlayButton.hide();
    });

    // Log errors and poll for stream start
    const PlayerEventType = videoJSPlayer.getIVSEvents().PlayerEventType;
    const PlayerErrorType = videoJSPlayer.getIVSEvents().ErrorType;
    ivsPlayer.addEventListener(PlayerEventType.ERROR, (e) => {
      // console.warn("Player Event - ERROR: ", e.message);
      if (e.type == PlayerErrorType.NOT_AVAILABLE) {
        // console.log("Player State - Waiting for stream start");
        $(".vjs-waiting.starting").removeClass("vjs-hidden");
        setTimeout(() => {
          // console.log("Player State - Attempting to start stream");
          videoJSPlayer.src(videoContainerEl.getAttribute("src"));
          videoJSPlayer.play();
        }, 5000);
      }
    });

    videoJSPlayer.enableIVSQualityPlugin();
    videoJSPlayer.src(videoContainerEl.getAttribute("src"));
    videoJSPlayer.play();
    playhead_tracking();
  };

  videoJSPlayer.ready(readyCallback);
};
