// https://stackoverflow.com/questions/64113404/bootstrap-5-referenceerror-bootstrap-is-not-defined
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    new bootstrap.Tooltip('[data-bs-toggle="tooltip"]')
  }
}
