// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "provider" ]

  switchProvider(event) {
    console.log(event.target.value)

    if (document.getElementById("test_fields")) {
      if (event.target.value == "" || event.target.value == "pandem") {
        document.getElementById("test_fields").classList.add("d-none")
      } else {
        document.getElementById("test_fields").classList.remove("d-none")
      }
    }

    for (let provider of this.providerTargets) {
      provider.classList.add("d-none")
    }

    var el = document.getElementById(event.target.value)
    if(el) { el.classList.remove("d-none") }

  }
}
