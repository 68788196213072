import moment from 'moment';

$(document).on('turbo:load', function () {
  if ($('#datepicker').length) {
    var start = moment($('#datepicker').data("valid-from"), 'MM/DD/YYYY');
    var end = moment($('#datepicker').data("valid-to"), 'MM/DD/YYYY');

    $('#datepicker').flatpickr({
      dateFormat: "m/d/Y",
      disable: [
        function (date) {
        if (date < start || date > end) {
          return true;
        }
        return false;
       }
      ],
    });
  }
});
