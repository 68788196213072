$(document).on('turbo:load', function(){
  $(".js-form-ymca input").each(function() {
    if ($(this).val().length > 0) {
      $(this).closest(".js-form-ymca").find("label").addClass("d-block");
    }
  });
  $(".js-form-ymca input").on("focus", function() {
    $(this).closest(".js-form-ymca").find("label").addClass("d-block");
  }).on('blur', function() {
    if ($(this).val().length == 0) {
      $(this).closest(".js-form-ymca").find("label").removeClass("d-block");
    }
  });
});
