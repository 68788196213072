import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["instructorName", "payRateId"]

  connect() {
    if (this.instructorNameTarget.value) {
      this.change()
    }
  }

  change() {
    var payRateId = this.payRateIdTarget.value
    if (this.instructorNameTarget.value !== "") {
      fetch(`/branch/pay_rates/${this.instructorNameTarget.value}?pay_rate_id=${payRateId}`)
        .then(r => r.text())
        .then(t => document.querySelector('#pay-rate-list').innerHTML = t)
    }
  }
}
