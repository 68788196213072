import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import 'tom-select';

export default class extends Controller {
  static targets = ["branchSelect"]
  static values = { url: String }

  connect() {
    new TomSelect(this.branchSelectTarget, {
      plugins: ['remove_button'],
      onChange: this.schedules.bind(this)
    });
  }

  updateSchedules() {
    const branchIds = Array.from(this.branchSelectTarget.selectedOptions).map(option => option.value);
    const url = `${this.urlValue}?branch_ids=${branchIds.join(",")}`;
    console.log("Fetching schedules for branch IDs:", branchIds);
    console.log("Attempting to fetch schedules with URL:", url);

    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
      })
      .catch(error => console.error('Error loading schedules:', error));
  }
}
