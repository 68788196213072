import ahoy from "ahoy.js";

$(document).on('turbo:load', function(){
  if ($(".video-container").length) {
    window._wq = window._wq || [];
    var hashedId = $(".video-container").children("iframe").data("hashed-id")

    _wq.push({ id: hashedId, onReady: function(video) {
      video.bind("play", function(video) {
        var video_id = $(".video-container").children("iframe").data("id");
        if ($(".video-container").children("iframe").data("challenge-id")) {
          var challenge_id = $(".video-container").children("iframe").data("challenge-id");
          var ahoy_params = { "video_id": video_id.toString(), "challenge_id": challenge_id.toString() };
        } else {
          var ahoy_params = { "video_id": video_id.toString() };
        }
        ahoy.track("Video Play", ahoy_params);
        // console.log("The video was just played!");
      });

      video.bind("secondchange", function (t) {
        var video_frame = $(".video-container").children("iframe");
        var interval = video_frame[0].hasAttribute("data-test-env") ? 1 : 15;

        if (t > 0 && Math.floor(t) % interval == 0) {
          // console.log("Playhead: ", t);
          var video_id = video_frame.data("id");
          ahoy.track(
            "Playhead Position",
            {
              "video_id": video_id.toString(),
              "position": t
            }
          )
        }
      });
    }});
  }
});
