import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.scheduled();
  }

  scheduled(e) {
    var selected = document.querySelector("input[name^='message[send_now]']:checked");
    var scheduleSelect = document.getElementById("messageSchedule")
    if (selected.value == "schedule") {
      scheduleSelect.classList.remove("d-none")
      scheduleSelect.querySelectorAll("select").forEach(
        function(currentSelect) { currentSelect.disabled = false }
      )
    } else {
      scheduleSelect.classList.add("d-none")
      scheduleSelect.querySelectorAll("select").forEach(
        function(currentSelect) { currentSelect.disabled = true }
      )
    }
  }
}
