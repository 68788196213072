import { Controller } from "@hotwired/stimulus"

import 'tom-select'
import TomSelect from "tom-select"

// Connects to data-controller="tom-select"
export default class extends Controller {
  connect() {
    if (this.element.getAttribute("multiple") == "multiple") {
      var settings = {
        maxOptions: null,
        plugins: ['remove_button'],
        hidePlaceholder: this.element.dataset.hidePlaceholder == "true" ? true : false
      }
    } else {
      var settings = {
        maxOptions: null
      }
    }

    new TomSelect(this.element, settings);
  }
}
