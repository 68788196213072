import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.liveVideoInit();
  }

  disconnect() {
    if (!videoJSPlayer.isDisposed()) {
      videoJSPlayer.dispose();
    }
  }
}
