import $ from 'jquery';
import datepicker from 'jquery-ui/ui/widgets/datepicker';

$(document).on('turbo:load', function() {
  $(function(){
    $('select#event_repeat_freq, input#event_date_string, input#event_end_date_string').on('change', function () {
      var startDateString = $("input#event_date_string").val(); //Ex: 10/31/2021
      var startDate = new Date(startDateString); //Ex: Sun Oct 31 2021 00:00:00 GMT-0700 (Pacific Daylight Time)

      var endDateString = $("input#event_end_date_string").val(); //Ex:  10/30/2022
      var endDate = new Date(endDateString); //Ex: Sun Oct 30 2022 00:00:00 GMT-0700 (Pacific Daylight Time)
      var weekDay = startDate.getDay(); // Sunday - Saturday : 0 - 6
      var repeatSelection = $('select#event_repeat_freq').children(':selected').text(); // ex: "Never"
      var week_of_the_year = datepicker.iso8601Week(startDate) // ex: 43
      var odd_or_even = week_of_the_year % 2; // 1 --> odd, 0 --> even

      var endingDate = (startDate > endDate) ? startDate : endDate; // Sun Oct 30 2022 00:00:00 GMT-0700 (Pacific Daylight Time)
      var endingDateString = endingDate.toLocaleDateString(); // ex: 10/30/2022
      var todayDate = new Date() // Fri Oct 28 2022 19:45:50 GMT-0700 (Pacific Daylight Time)

      // handle 'start time'
      const beginningOfToday = todayDate.setHours(0, 0, 0, 0) //Ex: 1666940400000
      const startTime = startDate.setHours(0, 0, 0, 0) //Ex: 1635663600000
      var startTimeString = new Date(startTime).toLocaleDateString()

      if (startTime >= beginningOfToday) {
        $("input#event_date_string").datepicker({
          minDate: todayDate,
          defaultDate: startTimeString,
        });
      } else {
        $("input#event_date_string").attr("disabled", true)
      }

      // Handle end time
      // recalculate start date
      var startDateString = $("input#event_date_string").val();
      var startDate = new Date(startDateString);

      $("input#event_end_date_string").val(endingDateString)
      $('.toggle_visibility_js').removeClass('d-none').addClass('d-flex');

      var endDateSettings = {
        minDate: (startDate > todayDate) ? startDate : endDate,
        defaultDate: endingDateString
      }

      // Do not display 'End Date' at all
      if(repeatSelection == "Never") {
        $('.toggle_visibility_js').addClass('d-none').removeClass('d-flex');

      } else if (repeatSelection == "Every Week") {
        var showTheseDays = {
          // Display only one day of the week
          beforeShowDay: function (date) { return [date.getDay() == weekDay, ""] }
        };

      } else if (repeatSelection == "Every 2 Weeks"){
        var showTheseDays = {
          // Display only one day of the week, every other week
          beforeShowDay: function(date){
          if (date.getDay() == weekDay && datepicker.iso8601Week(new Date(date)) % 2 == odd_or_even) {
                return [true, ''];
            } else {
                return [false, ''];
            }
          }
        }
      } else {
        var showTheseDays = {
          // Display every day of the week
          beforeShowDay: function(date) { return [true, ''] }
        }
      }

      let datepicker_options = { ...endDateSettings, ...showTheseDays }
      if ($("input#event_end_date_string.hasDatepicker").length == 0) {
        $("input#event_end_date_string").datepicker(datepicker_options);
      } else {
        $("input#event_end_date_string").datepicker("option", datepicker_options);
        $("input#event_end_date_string").datepicker("refresh");
      }
    });

    $('select#event_repeat_freq, input#event_date_string, input#event_end_date_string').trigger('change');

    $('#end-class-now').on('change', function() {
      if (this.checked) {
        $('#past-date-warning').removeClass("d-none")
        $("input.forever-js:checkbox").prop("checked", false)
      }
      else {
        $('#past-date-warning').addClass("d-none")
        $('select#event_repeat_freq, input#event_date_string, input#event_end_date_string').trigger('change');
        $(".end_date_js").removeClass("d-none");
      }
    })

    function toggle_field() {
      if ($("input.forever-js:checkbox").is(':checked')) {
        $(".end_date_js").addClass("d-none");
        $("#end-class-now").prop("checked", false);
        $("#past-date-warning").addClass("d-none")
      } else {
        $('select#event_repeat_freq, input#event_date_string, input#event_end_date_string').trigger('change');
        $(".end_date_js").removeClass("d-none");
      }
    }
    toggle_field();
    $("input.forever-js:checkbox").on("change", function (e) {
      toggle_field();
    });
  });
});
