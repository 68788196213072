import moment from 'moment';

$(document).on('turbo:load', function () {
  function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  function formatstring(dateString) {
    return moment(new Date(dateString)).format('MMM DD, YYYY');
  }

  // Function to set the fields; uses '#range-select-text' as default
  function setFields(start, end) {
    var formattedDateRange = formatstring(start) + " - " + formatstring(end);
    $("#range-select-text").val(formattedDateRange); // Always target this specific input
    $('#date_filters_end_date').val(end);
    $('#date_filters_start_date').val(start);
  }

  // Callback function used for analytics to submit form
  function applyCallback(selectedDates) {
    var from = formatDate(selectedDates[0]);
    var to = formatDate(selectedDates[1]);
    setFields(from, to);
    $("#flatpickr.analytics").closest('form')[0].requestSubmit(); // Make sure to target the correct form
  }

  var commonSettings = {
    showMonths: 2,
    mode: "range",
    position: "left",
    closeOnSelect: true,
    defaultDate: [$('#date_filters_start_date').val(), $('#date_filters_end_date').val()],
    onClose: function (selectedDates, dateStr, instance) {
      setFields(formatDate(selectedDates[0]), formatDate(selectedDates[1]));
    }
  };

  // Setup for analytics with form submission
  $('#flatpickr.analytics').flatpickr($.extend({}, commonSettings, {
    onClose: applyCallback
  }));

  // General setup for other instances
  $('#flatpickr.challenge').flatpickr(commonSettings);
  $('#flatpickr.instructor_report').flatpickr($.extend({}, commonSettings, {
    minDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days back from today
    maxDate: new Date(),
    onClose: function (selectedDates, dateStr, instance) {
      if (selectedDates.length === 1) {
        this.set('maxDate', new Date(selectedDates[0].getTime() + 90 * 24 * 60 * 60 * 1000)); // Set maxDate to 90 days from the selected date
      }
      setFields(formatDate(selectedDates[0]), formatDate(selectedDates[1]));
    }
  }));
});
