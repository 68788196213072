import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static values = { category: String }

  initialize() {
    this.connect = debounce(this.connect, 250).bind(this)
  }

  connect() {
    var width = window.innerWidth;
    var per = 4;
    var isWatchNow = this.categoryValue == "live";

    // 'per' must match with info on live_stream_carrusel.scss
    if (width < 768) {
      per = 2;
    } else if (width >= 768 && width < 992) {
      per = isWatchNow ? 2 : 3;
    } else if (width >= 992 && width < 1500) {
      per = isWatchNow ? 3 : 4;
    } else if (width >= 1500) {
      per = isWatchNow ? 4 : 5;
    }

    this.element.setAttribute("src", "/live_categories/" + this.categoryValue + "/?per=" + per);
  }

  arrow() {
    this.element.querySelector(".loading").classList.remove("d-none");
  }
}
