
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["platform"]

  switchPlatform(event) {
    event.preventDefault();
    console.log(event.target.value);

    var el = document.getElementById("device_desc")
    if (event.target.value === "") {
      el.classList.add("d-none")
    } else {
      el.classList.remove("d-none")
    }
  }
}
