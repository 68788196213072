import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "type"]

  connect() {
    for (let type of this.typeTargets) {
      if (type.id != this.selectTarget.value) {
        for (let input of type.querySelectorAll("input")) {
          if(input.type == "hidden") { continue }

          input.value = ""
        }
      }
    }

    this.switchType()
  }

  switchType() {
    for (let type of this.typeTargets) {
      type.classList.add("d-none")

      for (let input of type.querySelectorAll("input")) {
        input.disabled = true
      }
    }

    var el = document.getElementById(this.selectTarget.value)
    if (el) {
      el.classList.remove("d-none")
      if (this.element.dataset.disabled != "true") {
        for (let input of el.querySelectorAll("input")) {
          input.disabled = false
        }
      }
    }
    this.workoutType()
  }

  workoutType() {
    const workoutSelect = document.getElementById("workout-types-select")
    if (this.selectTarget.value == "" || this.selectTarget.value == "videos_watched") {
      workoutSelect.classList.add("d-none")
    } else {
      workoutSelect.classList.remove("d-none")
    }
  }
}
