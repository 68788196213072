import { Controller } from "stimulus"
import 'tom-select'
import TomSelect from "tom-select"
export default class extends Controller {
  connect() {
    var me = this
    var settings = {
      valueField: "id",
      searchField: ['displayString'],
      labelField: 'displayString',
      maxOptions: 50,
      maxItems: 1,
      load: function(query, callback) {
        fetch(`/${me.namespace()}/occurrences/${me.occurrenceId()}/reservations/members_lookup?string=${query}`, { method: 'get' })
        .then((response) => response.json())
        .then((items) => callback(items))
      }
    }
    new TomSelect(this.element, settings);
  }

  occurrenceId() {
    return(document.querySelector("#occurrence_id").value)
  }

  namespace() {
    return(document.querySelector("#namespace").value)
  }
}

