import Flickity from "flickity";
require('flickity-imagesloaded');

$(document).on('turbo:load', function(){
  if($('.carousel.home-hero').length) {
    new Flickity('.carousel.home-hero', {
      wrapAround: true,
      imagesLoaded: true,
      autoPlay: 6000
    });
  }

  if($('.carousel-live').length) {
    $('.carousel-live').each(function(){
      new Flickity(this, {
        cellAlign: "left",
        imagesLoaded: true,
        pageDots: false,
        contain: true
      });
    });
  }
});
