$(document).on('turbo:load', function () {
  const settings = {
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
    allowInput: true, //Allows the user to enter a date directly into the input field
    wrap: true //needed to add the 'clear' option
  }
  if ($('#datepicker_trialcode').length) {
    $('#datepicker_trialcode').flatpickr(settings);
  }

  if ($('#datepicker_hours_from').length) {
    $('#datepicker_hours_from').flatpickr(settings);
  }

  if ($('#datepicker_hours_to').length) {
    $('#datepicker_hours_to').flatpickr(settings);
  }

  if ($('#flatpickr_dob').length) {
    $('#flatpickr_dob').flatpickr(settings);
  }

  if ($('#flatpickr_challenge').length) {
    $('#flatpickr_challenge').flatpickr({
      altInput: true,
      altFormat: "m/d/Y",
      dateFormat: "m/d/Y",
      allowInput: true, //Allows the user to enter a date directly into the input field
      wrap: true //needed to add the 'clear' option
    });
  }

  if ($('#flatpickr_branch_occurrences').length) {
    $('#flatpickr_branch_occurrences').flatpickr({
      altInput: true,
      altFormat: "m/d/Y",
      dateFormat: "Y-m-d",
      allowInput: true,
    });
  }
});
