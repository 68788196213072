$(document).on('turbo:load', function() {
  $(window).resize(function() {
    if ($(window).outerWidth() < 767) {
       visible_table_width = $(window).outerWidth();
     }
    else {
      visible_table_width = $(window).outerWidth() - $(".sidebar").outerWidth() ;
      }
   if ( visible_table_width < $(".tab-pane.active .card-body .table-responsive tbody").outerWidth() + 64) {
      $(".scroll_snippet").removeClass("d-none");
   }
   else if ( visible_table_width < $(".tab-pane.active .table-responsive tbody").outerWidth() + 32) {
      $(".scroll_snippet").removeClass("d-none");
   }
   else if ( visible_table_width < $(".table-responsive tbody").outerWidth() + 32) {
      $(".scroll_snippet").removeClass("d-none");
   }
    else {
      $(".scroll_snippet").addClass("d-none");
    }
  }).resize();
});
