import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["select"]

  change(e) {
    const base_path = e.params.path
    const selectedScheduleId = this.selectTarget.value
    Turbo.visit(this.#appendToQueryString(e.params.path,`schedule_id=${selectedScheduleId}`), { action: "replace" })
  }

  #appendToQueryString(path, newQueryString) {
    const hasQueryString = path.includes('?');
    const separator = hasQueryString ? '&' : '?';
    return path + separator + newQueryString;
  }
}
