import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["seatVisibility", "resToggle",
                    "waitlistToggle", "programChoice"]

  connect() {
    this.toggleReservationsRequired()
  }

  // Stimulus actions

  toggleReservationsRequired() {
    if (this.reservationsRequiredIsChecked()) {
      this.showSeatInputField()
      if (this.chosenProgramCanHaveWaitlists()) {
        this.showWaitlistEnabledCheckbox()
      } else {
        this.hideWaitlistEnabledCheckbox()
        this.deselectWaitlistEnabled()
      }
    } else {
      this.hideSeatInputField()
      this.hideWaitlistEnabledCheckbox()
      this.deselectWaitlistEnabled()
    }
  }

  updateProgramChoice() {
    if (this.reservationsRequiredIsChecked()) {
      if (this.chosenProgramCanHaveWaitlists()) {
        this.showWaitlistEnabledCheckbox()
      } else {
        this.hideWaitlistEnabledCheckbox()
        this.deselectWaitlistEnabled()
      }
    }
  }

  // Helper methods

  hideSeatInputField() {
    this.seatVisibilityTarget.classList.add("d-none")
  }

  showSeatInputField() {
    this.seatVisibilityTarget.classList.remove("d-none")
  }

  hideWaitlistEnabledCheckbox() {
    this.waitlistToggleTarget.classList.add("d-none")
  }

  showWaitlistEnabledCheckbox() {
    this.setWaitlistTooltip()
    this.waitlistToggleTarget.classList.remove("d-none")
  }

  setWaitlistTooltip() {
    if (this.chosenProgramQueueLength() === undefined) {
      this.waitlistToggleTarget.querySelector("i").classList.add("d-none")
    } else {
      this.waitlistToggleTarget.querySelector("i").setAttribute(
        "data-original-title",
        `Waitlist length for this Schedule Category has been set to ${this.chosenProgramQueueLength()} by your Association administrator.`
      )
      this.waitlistToggleTarget.querySelector("i").classList.remove("d-none")
    }
  }

  chosenProgramCanHaveWaitlists() {
    return this.programChoiceTarget.options[this.programChoiceTarget.selectedIndex].dataset.waitlist === "true"
  }

  chosenProgramQueueLength() {
    return this.programChoiceTarget.options[this.programChoiceTarget.selectedIndex].dataset.queue
  }

  reservationsRequiredIsChecked() {
    return this.resToggleTarget.checked
  }

  deselectWaitlistEnabled() {
    this.waitlistToggleTarget.querySelector("input#event_waitlist_enabled").checked = false
  }
}
