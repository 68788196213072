import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.handleScroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    const element = document.getElementById('js-background')
    if (window.scrollY > 5) {
      element.classList.add('bg-on-scrolling')
    } else {
      element.classList.remove('bg-on-scrolling')
    }
  }
}
